import React from 'react';

import { Grid, GridItem, Heading, Text } from '@chakra-ui/react';

import content from '../../content';
import { Talent } from './Talent';

export const About = () => {
    const { self } = content;

    return (
        <Grid gap={{ base: 8, md: 16 }}>
            <Talent />
            <Grid
                gridTemplateColumns={{ base: '1fr', md: '1fr 2fr' }}
                justifyItems="space-between"
                gap={{ base: 8, md: 16 }}
            >
                <GridItem>
                    <Heading as="h3" fontWeight={300} fontSize={24} mt="4px">
                        <span className="red">Hello!</span> {self.shortBio}
                    </Heading>
                </GridItem>
                <GridItem>
                    <Text mb={4} lineHeight="1.75em">
                        In 2006, I dove into coding by creating custom MySpace themes, sparking a passion that has shaped my career ever since. Over the years, I've had the opportunity to build software for three software startups, collaborate with two digital product studios, and engage in long-term freelance work for local small businesses.
                    </Text>
                    <Text mb={4} lineHeight="1.75em">
                        My favorite projects are those that blend design and engineering seamlessly—where aesthetics meet solid, well-crafted code. In my free time, I like to experiment with small, self-contained apps.
                    </Text>
                    <Text lineHeight="1.75em">
                        When I'm not coding, you'll find me exploring National Parks with my kids, spending time with my <strikethrough>four</strikethrough>five dogs, or indulging in painting.
                    </Text>
                </GridItem>
            </Grid>
        </Grid>
    )
}
